import styled from 'styled-components';

export default styled.div`
  background: ${props => props.backgroundColor ? props.backgroundColor : 'white'};
  border-radius: 5% / 10%;
  box-shadow: ${props => props.theme.shadows.primary};
  padding-bottom: 58%;
  position: relative;
  transition: box-shadow, transform;
  transition-duration: 250ms;
  width: 100%;

  /* &:hover {
    box-shadow: ${props => props.theme.shadows.hover};
    transform: translateY(-.125em) scale(1.05);
  } */

  > img, > picture, .lazyWrapper {
    border-radius: 5% / 10%;
    display: block;
    left: 50%;
    margin: auto;
    max-height: calc(100%);
    max-width: calc(100%);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  > picture img, > .lazyWrapper img {
    max-width: calc(100%);
  }
`;
